import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { useEffect, useMemo, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { HiQuestionMarkCircle } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { userSelector } from "../../app/userRedux/userSlice.js";
import Logo from "../../components/firms/Logo";
import IncognitoModal from "../../components/IncognitoModal.jsx";
import MatchPercentageBadge from "../../components/MatchPercentageBadge";
import SanitizeString from "../../components/SanitizeString";
import { IMAGES } from "../../constants/images.jsx";
import { useConversation } from "../../context/ConversationContext";
import useJob from "../../hooks/jobs/useJob.ts";
import useFetchConversations from "../../hooks/useFetchConversations.js";
import useUpdateIncognito from "../../hooks/user/useUpdateIncognito";
import Conversation from "./Conversation.jsx";
import Messages from "./Messages.jsx";

const backgroundImageStyle = {
  backgroundImage: `url(${IMAGES.CHAT_BG})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
};
const tooltipContent =
  "By sliding the bar to the left, the law firm will see your full profile. If set to anonymous (default mode), the firm you’re chatting with will not be able to view your full profile. You can switch between anonymous and public modes as often as you want, giving you full control over your privacy at any time.";

const sortConversations = (conversationA, conversationB) => {
  const lastMessageAtDateA = conversationA.lastMessageAt?.toDate();
  const lastMessageAtDateB = conversationB.lastMessageAt?.toDate();

  if (lastMessageAtDateA !== undefined && lastMessageAtDateB === undefined) {
    return -1;
  } else if (
    lastMessageAtDateA === undefined &&
    lastMessageAtDateB !== undefined
  ) {
    return 1;
  }

  return lastMessageAtDateB - lastMessageAtDateA;
};

const ChatRoom = () => {
  const { state: routingState } = useLocation();

  const { activeConversationId, setActiveConversationId } = useConversation(
    routingState?.conversationId ?? null
  );
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const { updateIncognito, isUpdating } = useUpdateIncognito();
  const { conversations, loadingConversations } = useFetchConversations();
  const [show, setShow] = useState(false);

  const activeConversation = useMemo(() => {
    if (loadingConversations || activeConversationId == null) {
      return;
    }

    return conversations.find(
      (conversation) => conversation.id === activeConversationId
    );
  }, [conversations, loadingConversations, activeConversationId]);

  const messagingWith = useMemo(() => {
    return activeConversation?.conversationPartner;
  }, [activeConversation]);

  const { job: aboutJob } = useJob(
    activeConversation?.aboutJob.uid,
    Boolean(activeConversation),
    Boolean(messagingWith?.isEmployer) ? user.uid : messagingWith?.uid
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setActiveConversationId(routingState?.conversationId);
  }, [routingState?.conversationId, setActiveConversationId]);

  const handleConversationClick = (conversationId) => async () => {
    setActiveConversationId(conversationId);
  };

  const updateIncognitoStatus = async (incognito) => {
    if (activeConversation.incognito !== incognito) {
      await updateIncognito({
        conversationId: activeConversationId,
        incognito,
        userId: user.uid,
      });
    }
  };

  const groupedConversations = conversations?.reduce((groups, conversation) => {
    const { aboutJob } = conversation; // Extract aboutJob from the conversation

    // Extract the job title from the aboutJob object
    const jobTitle = aboutJob?.jobTitle;

    if (jobTitle) {
      if (!groups[jobTitle]) {
        groups[jobTitle] = [];
      }
      groups[jobTitle].push(conversation);
    } else {
      // Handle cases where jobTitle is not available
      console.error("Job title not found for conversation:", conversation);
    }

    return groups;
  }, {});

  const sortedConversations = [...conversations]?.sort(sortConversations);

  return (
    <div className="flex flex-column items-center text-white h-[89dvh] md:h-[90dvh] overflow-hidden overscroll-none bg-[#253549]">
      <div className="flex w-full h-full">
        {/* left */}
        <div
          className={`${messagingWith == null ? "flex" : "hidden md:flex"} flex-column w-full md:w-[22%] bg-[#131F2E]  py-1`}
        >
          {/* search section */}
          <div className="mt-3 w-full ">
            <h1 className="font-bold text-lg mx-4">Conversations</h1>
          </div>
          <div className="flex flex-column w-full mt-3 text-xs overflow-auto">
            <div className=" border-gray-300 pb-2">
              {loadingConversations ? (
                <DotLoader className=" mx-4" color="#ffffff" size={20} />
              ) : user.isEmployer ? (
                Object.entries(groupedConversations).map(
                  ([job, conversationsInJob]) => (
                    <div key={job}>
                      <h2 className="m-2 ">{job}</h2>
                      {conversationsInJob.map((conversation) => (
                        <Conversation
                          key={conversation.id}
                          conversation={conversation}
                          activeConversationId={activeConversationId}
                          onClick={handleConversationClick(conversation.id)}
                        />
                      ))}
                    </div>
                  )
                )
              ) : (
                sortedConversations.map((conversation) => (
                  <Conversation
                    key={conversation.id}
                    conversation={conversation}
                    activeConversationId={activeConversationId}
                    onClick={handleConversationClick(conversation.id)}
                  />
                ))
              )}
            </div>
          </div>
        </div>

        {/* mid */}
        <div
          className={`${messagingWith != null ? "flex" : "hidden md:flex"} flex flex-column items-center w-full md:w-[56%] relative`}
          style={backgroundImageStyle}
        >
          {/* top chat screen */}
          {messagingWith && (
            <div className="flex items-center w-100 h-16 bg-[#1E2C3E] px-2">
              <div className="flex  space-between w-full ">
                <div
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    if (user.isEmployer) {
                      navigate(
                        `/candidate-preview/${messagingWith.uid}/${aboutJob?.uid ?? ""}`,
                        {
                          state: {
                            conversationId: activeConversationId,
                          },
                        }
                      );
                    } else {
                      navigate(`/job-details-page/${aboutJob?.uid}`, {
                        state: {
                          conversationId: activeConversationId,
                        },
                      });
                    }
                  }}
                >
                  {messagingWith?.isEmployer ? (
                    <Logo src={messagingWith?.firmLogo} size="3rem" />
                  ) : (
                    <img
                      src={
                        activeConversation?.incognito
                          ? IMAGES.U_ICON
                          : messagingWith?.profileImg
                            ? messagingWith?.profileImg
                            : IMAGES.U_ICON
                      }
                      className="w-12 h-12 rounded-full object-cover"
                      alt=""
                    />
                  )}
                  <p className="font-bold pl-2">
                    {!messagingWith?.isEmployer
                      ? messagingWith?.userData?.name ||
                        !activeConversation?.incognito
                        ? messagingWith?.name + " " + messagingWith?.surname
                        : messagingWith?.name
                      : aboutJob?.jobTitle}
                  </p>
                </div>
                <button
                  className="md:hidden"
                  onClick={() => setActiveConversationId(null)}
                >
                  <IoMdClose size={22} className="" />
                </button>
              </div>
            </div>
          )}
          {!user.isEmployer &&
            messagingWith &&
            !aboutJob?.applicants.includes(user.uid) && (
              <>
                <div
                  className={`flex items-center justify-center  z-50 py-2 rounded-md absolute top-[2.4rem] lg:top-[2.4rem] px-2 cursor-pointer `}
                  onClick={() => handleShow()}
                  style={{
                    background: "rgba(70, 214, 234, 1)",
                  }}
                >
                  <div className="flex justify-around items-center w-full text-[#D8E0EB] ">
                    <img src={IMAGES.PUBLIC} />
                    <p
                      className={`font-bold pl-2 mr-2 `}
                      style={{
                        color: activeConversation?.incognito
                          ? `rgba(19, 31, 46, 0.5)`
                          : `rgba(19, 31, 46, 1)`,
                      }}
                    >
                      Public
                    </p>

                    <Form.Check // prettier-ignore
                      className="bg-red"
                      checked={activeConversation?.incognito}
                      type="switch"
                      id="custom-switch"
                      style={{
                        cursor: "pointer",
                      }}
                      onChange={() => {
                        handleShow();
                      }}
                    />
                    <p
                      className={`font-bold pl-2 mr-2 
                        `}
                      style={{
                        color: activeConversation?.incognito
                          ? "rgba(19, 31, 46, 1)"
                          : "rgba(19, 31, 46, 0.5)",
                      }}
                    >
                      Anonymous
                    </p>
                    <img src={IMAGES.INCOG_ICON} />

                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip
                          placement="bottom"
                          className="in"
                          id="tooltip-bottom"
                        >
                          {tooltipContent}
                        </Tooltip>
                      }
                    >
                      <p className="ml-2 text-[#131F2E]">
                        <HiQuestionMarkCircle />
                      </p>
                    </OverlayTrigger>
                  </div>
                </div>
                <IncognitoModal
                  show={show}
                  onClose={handleClose}
                  onUpdateIncognitoStatus={updateIncognitoStatus}
                  isIncognito={activeConversation?.incognito}
                  isUpdating={isUpdating}
                />
              </>
            )}
          {!messagingWith && (
            <div
              className={`hidden md:flex items-center justify-center flex-column h-[90%] w-full`}
            >
              <div className="flex items-center justify-center flex-column">
                <img src={IMAGES.JL_WHITE} className="h-7" alt="" />
                <p className="w-75 text-center mt-1 text-white">
                  Select a law firm on the left to start or continue a
                  conversation.
                </p>
              </div>
            </div>
          )}
          <div className="h-full w-full overscroll-auto overflow-auto">
            {activeConversation && messagingWith && (
              <Messages
                conversation={activeConversation}
                conversationPartner={messagingWith}
                key={activeConversationId}
              />
            )}
          </div>
        </div>
        {/* right */}
        <div className="hidden md:flex flex-column w-[22%] bg-[#131F2E] items-center px-3 py-1">
          {messagingWith && (
            <div
              className="cursor-pointer"
              onClick={() => {
                if (user.isEmployer) {
                  navigate(
                    `/candidate-preview/${messagingWith.uid}/${aboutJob?.uid ?? ""}`,
                    {
                      state: {
                        conversationId: activeConversationId,
                      },
                    }
                  );
                } else {
                  navigate(`/job-details-page/${aboutJob?.uid}`, {
                    state: {
                      conversationId: activeConversationId,
                    },
                  });
                }
              }}
            >
              {/* upper part */}
              <div className="flex flex-column items-center justify-center  w-[90%] px-3 mt-4 border-b-[1px]  border-gray-300 pb-3">
                {/* firm logo */}
                <div className="hidden md:inline-block">
                  {messagingWith?.isEmployer ? (
                    <Logo src={messagingWith?.firmLogo} size="6rem" border />
                  ) : (
                    <img
                      src={
                        activeConversation?.incognito
                          ? IMAGES.U_ICON
                          : messagingWith?.profileImg
                            ? messagingWith?.profileImg
                            : IMAGES.U_ICON
                      }
                      className="md:w-16 md:h-16 lg:w-24 lg:w-24 lg:h-24 border-2 border-yellow-400 rounded-full object-cover"
                      alt=""
                    />
                  )}
                </div>
                {/* firm info */}
                <div className="mt-3 text-xs lg:text-sm items-center flex flex-col">
                  <h3>
                    {!messagingWith?.isEmployer
                      ? messagingWith?.userData?.name ||
                        !activeConversation?.incognito
                        ? messagingWith?.name + " " + messagingWith?.surname
                        : messagingWith?.name
                      : aboutJob?.jobTitle}
                  </h3>
                </div>
              </div>
              {/* lower part */}
              <div className=" w-[90%] ">
                {/* position */}
                <div className="flex flex-column items-center justify-center text-center mt-4">
                  <div className="flex flex-col  lg:flex-row text-xs lg:text-sm">
                    <p className="font-normal">Regarding:&nbsp;</p>
                    <p className="font-bold">{aboutJob?.jobTitle}</p>
                  </div>

                  <p className="text-xs my-1">
                    {messagingWith?.isEmployer
                      ? ""
                      : JSON.stringify(messagingWith?.createdAt)}
                  </p>
                  {aboutJob?.matchedPercentage != null && (
                    <MatchPercentageBadge
                      style={{ color: "#FFF" }}
                      percentage={aboutJob?.matchedPercentage}
                    />
                  )}
                </div>
                {/* icons */}
                <div className="flex flex-column  mt-3  border-b-[1px]  border-gray-300 pb-3">
                  <div className="grid grid-cols-1 md:grid-cols-2 justify-around text-center items-center">
                    <p className="flex flex-column justify-center items-center text-xs ">
                      <CiLocationOn size={22} className="mb-2" />
                      {user.isEmployer
                        ? messagingWith?.cities.join(", ") || "No location"
                        : aboutJob?.jobCities.join(", ") || "No location"}
                    </p>
                    <p className="flex flex-column justify-center items-center text-xs ">
                      <PiSuitcaseLight size={22} className="mb-2" />
                      {user.isEmployer
                        ? messagingWith?.experience + " years"
                        : aboutJob?.jobExperienceMin +
                          " - " +
                          aboutJob?.jobExperienceMax +
                          " years"}
                    </p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 justify-around items-center text-center mt-2">
                    <p className="flex flex-column justify-center items-center text-xs ">
                      <BiMessage size={22} className="mb-2" />

                      {user.isEmployer
                        ? messagingWith?.selectedLanguages
                          ? messagingWith?.selectedLanguages?.join(" / ")
                          : "No languages"
                        : aboutJob?.jobLanguages?.join(" / ")}
                    </p>
                    <p className="flex flex-column justify-center items-center text-xs ">
                      <MdOutlineMenu size={22} className="mb-2" />

                      {}
                      {user.isEmployer
                        ? messagingWith?.expertise
                          ? messagingWith?.expertise?.join(", ")
                          : "No expertise"
                        : aboutJob?.jobExpertise?.join(", ")}
                    </p>
                  </div>
                </div>
                {/* description */}
                <div className="flex flex-column items-center mt-3 p-3 text-white">
                  <p className="text-xs font-bold">
                    {user.isEmployer ? "About User" : "About Job"}
                  </p>
                  <SanitizeString
                    className="text-justify mt-2 text-xs text-white"
                    content={
                      user.isEmployer
                        ? (messagingWith?.description ?? "")
                        : (aboutJob?.jobDescription ?? "")
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
