import classNames from "classnames";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { TbMessageCircle } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendNotification } from "../../app/functions.js";
import { E_JOB_STATUS } from "../../constants/enums";
import { IMAGES } from "../../constants/images.jsx";
import useAcceptCandidatesApplication from "../../hooks/candidates/useAcceptCandidatesApplication";
import useLikeCandidate from "../../hooks/candidates/useLikeCandidate";
import useRejectCandidatesApplication from "../../hooks/candidates/useRejectCandidatesApplication";
import useMyJobs from "../../hooks/jobs/useMyJobs";
import useCheckIfAnonymous from "../../hooks/useCheckIfAnonymous";
import useNavigateToConversation from "../../hooks/useNavigateToConversation";
import { experienceTotal } from "../../utils/helpers.js";
import ApplicationStatusJobCardHeader from "../ApplicationStatusJobCardHeader";
import MatchBadge from "../MatchBadge";
import MatchPercentageBadge from "../MatchPercentageBadge";
import RejectModal from "../modals/RejectModal.jsx";
import { NOTIFICATION_TYPES } from "../Notifications/index.js";
import styles from "./CandidateCard.module.css";

const ButtonWrapperTooltip = ({ children, isJobNotActive }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        isJobNotActive ? (
          <Tooltip placement="top" className="in fixed" id="tooltip-top">
            You can't take action on this candidate because the job is no longer
            active.
          </Tooltip>
        ) : (
          <></>
        )
      }
    >
      <div className="flex-col w-full">{children}</div>
    </OverlayTrigger>
  );
};

const isJobMarked = (job, savedJobs) =>
  job != null && savedJobs?.includes(job?.uid);
const areAnyJobsMarked = (jobs, savedJobs) =>
  jobs?.some((job) => savedJobs?.includes(job?.uid));

const CandidateCard = ({ data, job, origin, onClick, onLike, onMessage }) => {
  const userData = useSelector((state) => state.user.userData);
  const { myJobs } = useMyJobs();
  const { likeCandidate } = useLikeCandidate();
  const { rejectApplication } = useRejectCandidatesApplication();
  const { acceptApplication } = useAcceptCandidatesApplication();
  const [liked, setLiked] = useState(false);
  const [matched, setMatched] = useState(false);
  const [marked, setMarked] = useState(
    job != null
      ? isJobMarked(job, data?.saved)
      : areAnyJobsMarked(myJobs, data?.saved)
  );
  const [showRejectModal, setShowRejectModal] = useState(false);
  const { incognito: isIncognito } = useCheckIfAnonymous(
    userData.uid,
    data.uid,
    job?.uid
  );

  const { navigateToConversation } = useNavigateToConversation();
  const navigate = useNavigate();

  useEffect(() => {
    setMarked(
      job != null
        ? isJobMarked(job, data?.saved)
        : areAnyJobsMarked(myJobs, data?.saved)
    );
  }, [data, myJobs, job]);

  const handleRejectCandidate = () => {
    setShowRejectModal(false);
    rejectApplication(
      { applicationId: data.applicationData.application_id },
      {
        onSuccess: () => {
          setShowRejectModal(false);
          sendNotification(
            data.uid,
            job.uid,
            job.uid,
            NOTIFICATION_TYPES.REJECT_CANDIDATE
          );
        },
      }
    );
  };

  if (data?.rejected?.includes(job?.uid)) {
    return <></>;
  }

  const handleViewCandidate = () => {
    if (onClick) {
      onClick();
      return;
    }

    navigate(`/candidate-preview/${data.uid}/${job?.uid ?? ""}`);
  };

  const isJobNotActive = job != null && job.jobStatus !== E_JOB_STATUS.active;

  return (
    <div className={styles.container}>
      <div>
        {data?.applicationData != null && (
          <ApplicationStatusJobCardHeader
            applicationData={data.applicationData}
          />
        )}
      </div>
      <div
        className={classNames(
          "flex flex-column bg-white px-4 py-4 relative text-[#131F2E] cursor-pointer w-full justify-between flex-col flex-1",
          {
            "rounded-b-md": data?.applicationData != null,
            "rounded-md": data?.applicationData == null,
          }
        )}
      >
        <div
          className="flex flex-column h-full"
          onClick={() => {
            if (data?.applicationData?.rejected ?? false) return;
            handleViewCandidate();
          }}
        >
          {data?.applicationData?.rejected && (
            <div className="bg-white w-full h-full absolute top-0 left-0 bg-opacity-50 cursor-default" />
          )}
          {origin === "matches" && <MatchBadge />}
          {/* match candidate info */}
          {data?.isStudent && (
            <div className="absolute top-0 right-5">
              <img src={IMAGES.STUDENT} width={42} height={45} alt="" />
            </div>
          )}
          <div>
            <div className="flex items-center gap-x-2">
              <div className="flex md:flex-row items-center w-full gap-3">
                {data?.profileImg ? (
                  <img
                    src={!isIncognito ? data.profileImg : IMAGES.U_ICON}
                    className="w-16 h-16 rounded-full border-2 border-green-600 object-cover"
                    alt=""
                  />
                ) : (
                  <img
                    src={IMAGES.U_ICON}
                    className="w-16 h-16 rounded-full border-2 border-green-600 object-cover"
                    alt=""
                  />
                )}
                <h1 className={styles.title}>
                  {isIncognito ? (
                    data?.name ? (
                      <>{data.name}</>
                    ) : (
                      <>Anonymous</>
                    )
                  ) : (
                    <>
                      {data.name} {data.surname}
                    </>
                  )}
                </h1>
              </div>
            </div>
            <div className="py-2 flex flex-col-reverse md:flex-row items-center text-[#1B1F87]/50">
              {data?.matchedPercentage != null && (
                <div className="w-full md:w-50 flex justify-center md:justify-end">
                  <MatchPercentageBadge percentage={data.matchedPercentage} />
                </div>
              )}
            </div>
          </div>
          {/* match candidate experience */}
          <div className="flex justify-between w-full mt-0 py-4 border-t-2 border-gray-300 h-fit">
            <div className="flex flex-col gap-3">
              <p className="flex flex-row items-center">
                <CiLocationOn size={20} className="mr-2" />
                <span className="text-sm font-semibold">
                  {data?.cities == null || data?.cities.length === 0 ? (
                    <>Location not added</>
                  ) : (
                    <> {data.cities.join(", ")}</>
                  )}
                </span>
              </p>
              <p className="flex flex-row items-center">
                <MdOutlineMenu className="mr-2" />
                {data?.expertise == "" || data?.expertise == null ? (
                  <span className="text-sm font-semibold">
                    Expertise not disclosed
                  </span>
                ) : (
                  <span className="text-sm font-semibold">
                    {data?.expertise.join(", ")}{" "}
                  </span>
                )}
              </p>
              <p className="flex flex-row items-center">
                <PiSuitcaseLight className="mr-2" />
                <span className="text-sm font-semibold">
                  {experienceTotal(data)}
                </span>
              </p>
              <p className="flex flex-row items-center">
                <BiMessage className="mr-2" />
                {data?.selectedLanguages == "" ||
                data?.selectedLanguages == null ? (
                  <span className="text-sm font-semibold">
                    No language specified
                  </span>
                ) : (
                  <span className="text-sm font-semibold">
                    {data?.selectedLanguages?.join(", ")}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className={styles.description}>{data.description}</div>
        </div>
        <div>
          {/* match candidate desc */}
          {((data?.applicationData?.cv != null &&
            data?.applicationData?.cv !== "" &&
            origin == "applicants") ||
            data?.applicationData?.coverLetter != null) && (
            <div className="flex flex-column justify-center items-center w-full gap-y-2 py-8">
              <div className="flex items-center justify-center gap-x-4 w-full md:w-[50%] h-10">
                {data?.applicationData?.cv != null &&
                  data?.applicationData?.cv !== "" &&
                  origin == "applicants" && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          data?.applicationData?.cv
                            ? data?.applicationData?.cv
                            : undefined
                        );
                      }}
                      target="_blank"
                      className="flex flex-column items-center justify-center cursor-pointer  px-4 py-1  bg-[#D9D9D9]/[40%]  rounded-md mx-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <path
                          d="M32.5 2.5H7.5C6.80859 2.5 6.25 3.05859 6.25 3.75V36.25C6.25 36.9414 6.80859 37.5 7.5 37.5H32.5C33.1914 37.5 33.75 36.9414 33.75 36.25V3.75C33.75 3.05859 33.1914 2.5 32.5 2.5ZM22.3438 5.3125H26.0938V13.5117L24.2773 12.1875L22.3438 13.5703V5.3125ZM9.0625 34.6875V5.3125H20V16.9102C20 17.0391 20.0391 17.168 20.1172 17.2734C20.1646 17.3405 20.2249 17.3974 20.2944 17.4411C20.364 17.4847 20.4416 17.514 20.5226 17.5275C20.6036 17.5409 20.6865 17.5382 20.7664 17.5194C20.8463 17.5006 20.9217 17.4661 20.9883 17.418L24.2617 15.0781L27.4414 17.3984C27.5469 17.4766 27.6758 17.5195 27.8086 17.5195C28.1523 17.5195 28.4336 17.2383 28.4336 16.8945V5.3125H30.9336V34.6875H9.0625Z"
                          fill="#8689D0"
                        />
                        <path
                          d="M26.0937 13.5117V5.3125H22.3437V13.5703L24.2773 12.1875L26.0937 13.5117Z"
                          fill="#E6F7FF"
                        />
                        <path
                          d="M28.4336 5.3125V16.8945C28.4336 17.2383 28.1523 17.5195 27.8086 17.5195C27.6758 17.5195 27.5469 17.4766 27.4414 17.3984L24.2617 15.0781L20.9883 17.418C20.9217 17.4661 20.8463 17.5006 20.7664 17.5194C20.6865 17.5382 20.6036 17.5409 20.5226 17.5275C20.4416 17.514 20.364 17.4847 20.2944 17.4411C20.2249 17.3974 20.1646 17.3405 20.1172 17.2734C20.0391 17.168 20 17.0391 20 16.9102V5.3125H9.0625V34.6875H30.9336V5.3125H28.4336Z"
                          fill="#E6F7FF"
                        />
                      </svg>
                      <div className="justify-center flex-column column items-center">
                        <span className="text-xs text-[#363BB199] font-bold">
                          CV
                        </span>
                        <span className="text-xs text-[#363BB199] font-bold">
                          &nbsp;
                        </span>
                      </div>
                    </button>
                  )}
                {data?.applicationData?.coverLetter != null &&
                  data?.applicationData?.coverLetter !== "" &&
                  origin === "applicants" && (
                    <button
                      onClick={(e) => {
                        console.log("clicked", data.applicationData);

                        e.stopPropagation();
                        window.open(
                          data.applicationData.coverLetter
                            ? data.applicationData.coverLetter
                            : undefined
                        );
                      }}
                      target="_blank"
                      className="flex flex-column items-center justify-center cursor-pointer  px-4 py-1  bg-[#D9D9D9]/[40%]  rounded-md mx-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="43"
                        height="43"
                        viewBox="0 0 43 43"
                        fill="none"
                      >
                        <path
                          d="M22.4238 14.7812V5.71094H9.74219V37.2891H33.2578V16.5449H24.1875C23.7197 16.5449 23.2711 16.3591 22.9404 16.0284C22.6096 15.6976 22.4238 15.249 22.4238 14.7812ZM21.5 28.3027C21.5 28.4875 21.3488 28.6387 21.1641 28.6387H13.4375C13.2527 28.6387 13.1016 28.4875 13.1016 28.3027V26.2871C13.1016 26.1023 13.2527 25.9512 13.4375 25.9512H21.1641C21.3488 25.9512 21.5 26.1023 21.5 26.2871V28.3027ZM29.8984 20.5762V22.5918C29.8984 22.7766 29.7473 22.9277 29.5625 22.9277H13.4375C13.2527 22.9277 13.1016 22.7766 13.1016 22.5918V20.5762C13.1016 20.3914 13.2527 20.2402 13.4375 20.2402H29.5625C29.7473 20.2402 29.8984 20.3914 29.8984 20.5762Z"
                          fill="#E6F7FF"
                        />
                        <path
                          d="M35.8865 12.1189L26.8498 3.08223C26.5979 2.83027 26.2577 2.6875 25.9008 2.6875H8.0625C7.31924 2.6875 6.71875 3.28799 6.71875 4.03125V38.9688C6.71875 39.712 7.31924 40.3125 8.0625 40.3125H34.9375C35.6808 40.3125 36.2812 39.712 36.2812 38.9688V13.0722C36.2812 12.7152 36.1385 12.3709 35.8865 12.1189ZM25.2793 5.78652L33.1822 13.6895H25.2793V5.78652ZM33.2578 37.2891H9.74219V5.71094H22.4238V14.7812C22.4238 15.249 22.6096 15.6976 22.9404 16.0284C23.2711 16.3591 23.7197 16.5449 24.1875 16.5449H33.2578V37.2891Z"
                          fill="#8689D0"
                        />
                        <path
                          d="M13.1016 20.5762V22.5918C13.1016 22.7766 13.2527 22.9277 13.4375 22.9277H29.5625C29.7473 22.9277 29.8984 22.7766 29.8984 22.5918V20.5762C29.8984 20.3914 29.7473 20.2402 29.5625 20.2402H13.4375C13.2527 20.2402 13.1016 20.3914 13.1016 20.5762ZM21.1641 25.9512H13.4375C13.2527 25.9512 13.1016 26.1023 13.1016 26.2871V28.3027C13.1016 28.4875 13.2527 28.6387 13.4375 28.6387H21.1641C21.3488 28.6387 21.5 28.4875 21.5 28.3027V26.2871C21.5 26.1023 21.3488 25.9512 21.1641 25.9512Z"
                          fill="#8689D0"
                        />
                      </svg>
                      <div className="justify-center flex-column column items-center">
                        <span className="text-xs text-[#363BB199] font-bold">
                          Cover
                        </span>
                        <span className="text-xs text-[#363BB199] font-bold">
                          Letter
                        </span>
                      </div>
                    </button>
                  )}
              </div>
            </div>
          )}
          {!matched &&
            !liked &&
            !data?.applicationData?.accepted &&
            !data?.applicationData?.rejected && (
              <>
                {origin == "applicants" && (
                  <div className="flex flex-row items-center justify-center  gap-x-3 w-full space-between">
                    <ButtonWrapperTooltip isJobNotActive={isJobNotActive}>
                      <Button
                        disabled={isJobNotActive}
                        variant="reject"
                        className="rounded-full flex-1 w-full"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRejectModal(true);
                        }}
                      >
                        <img
                          src={IMAGES.RED_CROSS}
                          width={20}
                          height={20}
                          alt=""
                        />
                        Reject
                      </Button>
                    </ButtonWrapperTooltip>
                    <ButtonWrapperTooltip isJobNotActive={isJobNotActive}>
                      <Button
                        className="rounded-full flex-1  w-full"
                        disabled={isJobNotActive}
                        variant={"like"}
                        onClick={(e) => {
                          e.stopPropagation();
                          acceptApplication(
                            {
                              applicationId:
                                data.applicationData.application_id,
                            },
                            {
                              onSuccess: () => {
                                setLiked(true);
                                sendNotification(
                                  data.uid,
                                  job.uid,
                                  job.uid,
                                  NOTIFICATION_TYPES.ACCEPT_CANDIDATE
                                );
                              },
                            }
                          );
                        }}
                      >
                        <img
                          src={IMAGES.CHECK_MARK_BLUE}
                          color=""
                          width={15}
                          height={14}
                        />
                        Accept
                      </Button>
                    </ButtonWrapperTooltip>
                  </div>
                )}
                <div
                  className={`${styles.buttons} flex items-center justify-center mt-3 gap-x-3 w-full space-between`}
                >
                  <ButtonWrapperTooltip isJobNotActive={isJobNotActive}>
                    <Button
                      disabled={isJobNotActive}
                      className="flex-1 text-[#131F2E] border-[#131F2E] p-1  w-full"
                      variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onMessage) {
                          onMessage(data.uid);
                          return;
                        } else {
                          navigateToConversation(data.uid, job?.uid);
                        }
                      }}
                    >
                      <p className="flex items-center mb-1 cursor-pointer hover:font-bold hover:text-black gap-1">
                        <TbMessageCircle size={20} />
                        Ask a question
                      </p>
                    </Button>
                  </ButtonWrapperTooltip>
                  {origin !== "applicants" && (
                    <ButtonWrapperTooltip isJobNotActive={isJobNotActive}>
                      <Button
                        disabled={isJobNotActive}
                        className="flex-1 p-1  w-full"
                        variant={marked ? "like-active" : "like"}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (onLike) {
                            onLike(data.uid);
                            return;
                          }
                          likeCandidate(
                            { candidateId: data.uid, jobId: job?.uid },
                            {
                              onSuccess: (newData) => {
                                setMarked(!marked);

                                if (!data.saved.includes(job.uid)) {
                                  if (newData.isMatch) {
                                    sendNotification(
                                      job.userid,
                                      data.uid,
                                      job.uid,
                                      NOTIFICATION_TYPES.MATCH_JOB
                                    );
                                    sendNotification(
                                      data.uid,
                                      job.uid,
                                      job.uid,
                                      NOTIFICATION_TYPES.MATCH_CANDIDATE
                                    );
                                  } else {
                                    sendNotification(
                                      data.uid,
                                      job.uid,
                                      job.uid,
                                      NOTIFICATION_TYPES.LIKE_CANDIDATE
                                    );
                                  }
                                }
                              },
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            marked
                              ? IMAGES.GREEN_HEART_FILLED
                              : IMAGES.GREEN_HEART
                          }
                          width={15}
                          height={14}
                        />
                        {marked ? "Liked" : "Like"}
                      </Button>
                    </ButtonWrapperTooltip>
                  )}
                </div>
              </>
            )}
          {origin == "applicants" &&
            (data?.applicationData?.accepted ||
              data?.applicationData?.rejected) && (
              <ButtonWrapperTooltip isJobNotActive={isJobNotActive}>
                <Button
                  disabled={isJobNotActive}
                  className="flex-1 text-[#131F2E] border-[#131F2E] p-1 mt-3  w-full"
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (data?.applicationData?.rejected) {
                      return;
                    }
                    if (onMessage) {
                      onMessage(data.uid);
                    } else {
                      navigateToConversation(data.uid, job?.uid);
                    }
                  }}
                >
                  <p className="flex items-center mb-1 cursor-pointer hover:font-bold hover:text-black gap-1">
                    <TbMessageCircle size={20} />
                    Start the conversation
                  </p>
                </Button>
              </ButtonWrapperTooltip>
            )}
        </div>
      </div>
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
        onReject={handleRejectCandidate}
      />
    </div>
  );
};

export default CandidateCard;
