import { css } from "@emotion/react";
import { useState } from "react";
import { PiCaretCircleLeftFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { IMAGES } from "../../constants/images.jsx";
import styles from "./Register.module.css";
import useRegister from "../../hooks/useRegister";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, ref, boolean } from "yup";
import Privacy from "../../assets/pdf/Privacy-policy.pdf";
import Terms from "../../assets/pdf/Terms.pdf";

const LANDING_PAGE = import.meta.env.VITE_LANDING_PAGE;

// Min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

const validationSchema = object({
  email: string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: string()
    .matches(passwordRules, {
      message:
        "Password must be at least 5 characters, contain 1 uppercase letter, 1 lowercase letter, and 1 number",
    })
    .required("Password is required"),
  passwordConfirmation: string()
    .required("Password confirmation is required")
    .oneOf([ref("password")], "Passwords must match"),
  privacy: boolean().oneOf([true], "You must accept the privacy policy"),
}).required();

const Register = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { registerUser } = useRegister();

  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
      privacy: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      registerUser(
        { email: data.email, password: data.password },
        {
          onSuccess: async () => {
            navigate("/candidateSignupVerify", {
              state: { email: data.email },
            });
          },
          onError: (error) => {
            setError({ message: error });
          },
        }
      );
    } catch (err) {
      setError({ message: err.message });
    } finally {
      setLoading(false);
    }
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className={styles.container}>
      {/* Box Container */}
      <div className={styles.boxcontainerBtn}>
        <Link to={LANDING_PAGE} className="flex items-center text-white">
          <PiCaretCircleLeftFill
            size={38}
            className="mr-2 text-blue-400 cursor-pointer duration-75 hover:scale-110"
          />
          <p className="">Back to landing page</p>
        </Link>
      </div>
      <div className={styles.boxcontainer}>
        <img
          src={IMAGES.JLFULL}
          className="w-18 h-10 mb-0"
          alt="JustLawyers logo"
        />
        <h3 className="w-[40%] text-left font-bold uppercase text-[#738397]">
          Candidate
        </h3>
        <div className={styles.innercontainer}>
          <div className={styles.littletittle}>
            <p>Register</p>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register("email")}
                className={styles.input}
                type="email"
                placeholder="Email"
              />
              {errors.email && (
                <p className="text-red-600 flex justify-start">
                  {errors.email.message}
                </p>
              )}

              <input
                {...register("password")}
                className={styles.input}
                type="password"
                placeholder="Password"
              />
              {errors.password && (
                <p className="text-red-600 flex justify-start">
                  {errors.password.message}
                </p>
              )}

              <input
                {...register("passwordConfirmation")}
                className={styles.input}
                type="password"
                placeholder="Confirm password"
              />
              {errors.passwordConfirmation && (
                <p className="text-red-600 flex justify-start">
                  {errors.passwordConfirmation.message}
                </p>
              )}

              <div className="flex">
                <Form.Check // prettier-ignore
                  className="flex font-normal justify-center items-center gap-2"
                  {...register("privacy")}
                  type="checkbox"
                  id="privacy"
                  label={
                    <p className="text-left">
                      I have read and agree to the general{" "}
                      <a
                        target="_blank"
                        style={{
                          color: "#182637",
                          textDecoration: "underline",
                        }}
                        href={Terms}
                      >
                        terms & conditions
                      </a>{" "}
                      {""}
                      and the{" "}
                      <a
                        target="_blank"
                        style={{
                          color: "#182637",
                          textDecoration: "underline",
                        }}
                        href={Privacy}
                      >
                        privacy policy
                      </a>
                      .
                    </p>
                  }
                />
              </div>
              {errors.privacy && (
                <p className="text-red-600 flex justify-start">
                  {errors.privacy.message}
                </p>
              )}
              {error && (
                <div className="bg-red-500 mt-2 rounded-md">
                  <p>{error.message}</p>
                </div>
              )}

              <div className="flex justify-center mt-4">
                <Button disabled={!isDirty || loading} type="submit">
                  {loading ? (
                    <DotLoader
                      color={"#ffffff"}
                      loading={loading}
                      css={override}
                      size={20}
                    />
                  ) : (
                    "Create an account"
                  )}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Register;
