import { useState } from "react";
import { useSelector } from "react-redux";
import { DotLoader } from "react-spinners";

import { sendNotification } from "../../app/functions";
import CandidateCard from "../../components/CandidateCard";
import Layout from "../../components/Layout.jsx";
import WhichJobModal from "../../components/modals/WhichJobModal.jsx";
import { NOTIFICATION_TYPES } from "../../components/Notifications";
import SearchBar from "../../components/SearchBar.jsx";
import useCandidates from "../../hooks/candidates/useCandidates";
import useLikeCandidate from "../../hooks/candidates/useLikeCandidate";
import useNavigateToConversation from "../../hooks/useNavigateToConversation.js";
import styles from "./RecruiterCandidatesPage.module.css";

const RecruiterCandidatesPage = () => {
  const userData = useSelector((state) => state.user.userData);
  const { likeCandidate } = useLikeCandidate();
  const [candidateToBeLiked, setCandidateToBeLiked] = useState(null);
  const [candidateToBeMessaged, setCandidateToBeMessaged] = useState(null);
  const { navigateToConversation } = useNavigateToConversation();
  const [filters, setFilters] = useState({});
  const { candidates, isCandidatesLoading } = useCandidates(filters);

  const isFiltered = Object.values(filters).some((filter) => {
    if (Array.isArray(filter)) {
      return filter.length !== 0;
    } else if (typeof filter == "boolean" || typeof filter == "string") {
      return filter;
    }

    return false;
  });

  const handleLike = (candidate) => () => {
    setCandidateToBeLiked(candidate);
  };

  const handleMessage = (candidate) => () => {
    setCandidateToBeMessaged(candidate);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h1 className={styles.title}>Candidate Board</h1>
          <div className="flex flex-col justify-center gap-y-2 p-0 mt-4">
            <div className="flex flex-row items-center space-between">
              <p>
                <span className="font-bold">{candidates?.length}</span> match
                your criteria
              </p>
              <div className="flex">
                <SearchBar
                  isFiltered={isFiltered}
                  onSearch={setFilters}
                  onClear={setFilters}
                  userData={userData}
                />
                <span className="ml-3 pt-2">
                  {candidates?.length} {isFiltered && <>filtered </>} results
                </span>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 gap-5 text-black relative auto-rows-max grid-auto-column gap-x-5">
              {isCandidatesLoading ? (
                <DotLoader color="#868bff" />
              ) : (
                candidates?.map((candidate, i) => (
                  <CandidateCard
                    key={i}
                    data={candidate}
                    origin="candidateboard"
                    onLike={handleLike(candidate)}
                    candidateToBeLiked={candidateToBeLiked}
                    onMessage={handleMessage(candidate)}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <WhichJobModal
        show={Boolean(candidateToBeLiked)}
        candidate={candidateToBeLiked}
        onClose={() => setCandidateToBeLiked(null)}
        onJobSelect={async (job) => {
          likeCandidate(
            { candidateId: candidateToBeLiked.uid, jobId: job.uid },
            {
              onSuccess: (newData) => {
                if (!candidateToBeLiked.saved.includes(job.uid)) {
                  if (newData.isMatch) {
                    sendNotification(
                      job.userid,
                      candidateToBeLiked.uid,
                      job.uid,
                      NOTIFICATION_TYPES.MATCH_JOB
                    );
                    sendNotification(
                      candidateToBeLiked.uid,
                      job.uid,
                      job.uid,
                      NOTIFICATION_TYPES.MATCH_CANDIDATE
                    );
                  } else {
                    sendNotification(
                      candidateToBeLiked.uid,
                      job.uid,
                      job.uid,
                      NOTIFICATION_TYPES.LIKE_CANDIDATE
                    );
                  }
                }
              },
            }
          );
        }}
      >
        Select the job you want to like the candidate for:
      </WhichJobModal>
      <WhichJobModal
        show={Boolean(candidateToBeMessaged)}
        candidate={candidateToBeMessaged}
        onClose={() => setCandidateToBeMessaged(null)}
        onJobSelect={(job) => {
          navigateToConversation(candidateToBeMessaged.uid, job.uid);
        }}
      >
        Select the job you want to message the candidate about:
      </WhichJobModal>
    </>
  );
};

export default RecruiterCandidatesPage;
